<template>
  <div class="fanganList">
    <div class="fanganCont mainLayout">
      <div
        class="fanganItem"
        v-for="item in newsListArr"
		@click="goTail(item.id)"
      >
		<img :src="item.imgUrl"/>
        <div>
          <span><i class='el-icon-files'></i></span>
          <h3>{{item.newsTitle}}</h3>
          <router-link :to="{path:'/subpage/jjfa/news_xq',query:{newsId:item.id}}" >查看更多<i class='el-icon-plus'></i></router-link>
        </div>
      </div>

    </div>
	
	<div class="fenye">
		<el-pagination
		background
		  :total="total"
		  :page.sync="pageNum"
		  :limit.sync="pageSize"
		  :page-size="6"
		  @pagination="newsListByChannelId"
		  @current-change="handleCurrentChange"
		/>
	  <!-- <el-pagination
	    background
	    layout="prev, pager, next"
	    :total="1000"
	  >
	  </el-pagination> -->
	</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
		//栏目id
		channelId:8,
      //新闻列表
      newsListArr: [],
      // 总条数
      total: 0,
      // 查询参数
      pageNum: 1,
      pageSize: 6,
    };
  },
  mounted() {
    //根据栏目Id查询新闻列表信息
    this.newsListByChannelId();
  },
  watch: {},
  methods: {
	//点击进入解决详情页面
	goTail(newsId){
			  // path:'/subpage/jjfa/news_xq',query:{newsId:item.id
			  this.$router.push({path:'/subpage/jjfa/news_xq',query:{newsId:newsId}})
	},
	  
    handleCurrentChange(val){
      this.pageNum = val;
      this.newsListByChannelId();
    },
    newsListByChannelId(){
      let _this = this;
      //根据栏目Id查询新闻列表信息
      this.$axios.get('/api/article/newsByChannelList',{params:{channelId:this.channelId,pageNum:this.pageNum,pageSize:this.pageSize}}).then(response => {
        let data = response.data.rows;
        this.total = response.data.total;
        this.newsListArr=data;
      });

    },

  }
};
</script>