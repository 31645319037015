<template>
  <div>
    <div class="singleTitle">
      <h3>智慧党建</h3>
    </div>
    <div class="djFlex">
      <div class="dj_left">
        <h3>所有专栏</h3>
        <!-- <router-link
          :to="{ path: '/subPage/zhdj/dj_list',query:{childrenId:item.id}}" @click.native="flushCom"
          v-for="item in channelListArr"
          :class="{cur:item===1}" 
        ><i class='el-icon-notebook-1'></i>{{item.title}}</router-link> -->
		<ul>
			<li   
				  v-for="item in channelListArr" v-model="channelId" @click="selectNav(item.title)">
				<a  @click="newsByChannelId(item.id,item.title)" :class="isSelect === item.title ? 'cur' : ''"
				><i class='el-icon-notebook-1'></i>{{item.title}}</a>
			</li>
		</ul>
      </div>

      <div class="dj_right">
          <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }"><i class='el-icon-s-home'></i>首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/subPage/zhdj/dj_list'} " >智慧党建</el-breadcrumb-item>
          <el-breadcrumb-item class="childrenName">{{childrenName}}</el-breadcrumb-item>
        </el-breadcrumb>
       <!-- <router-view /> -->
		<ul class="textListUl">
		     <li v-for="item in newsListArr"> 
		       <router-link :to="{path:'/dj_news_xq', query:{newsId:item.id}}" >{{item.newsTitle}}</router-link><span>{{item.createTime.substring(0,10)}}</span>
				<!-- <a :href="'/subpage/zhdj_news/dj_news_xq?newsId=' + item.id" >{{item.newsTitle}}</a><span>{{item.createTime.substring(0,10)}}</span> -->
			 </li>
		   </ul> 
	<div class="fenye">
			<el-pagination
			background
			  :total="total"
			  :page.sync="pageNum"
			  :limit.sync="pageSize"
			  :page-size="5"
			  @pagination="newsListByChannelId"
			  @current-change="handleCurrentChange"
			/>
	   </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
		//专题id
		channelId:5,
		//栏目列表
		channelListArr:[],
		childrenName:null,
		isSelect: '党建宣传', 
		//新闻列表
		newsListArr: [],
		// 总条数
		total: 0,
		// 查询参数
		pageNum: 1,
		pageSize: 5,
    };
  },
  mounted() {
    this.channelChildrenById();
	//根据栏目Id查询新闻列表信息
	this.newsListByChannelId();
  },
  watch: {},
  methods: {
	  
	selectNav (title) { 
		this.isSelect = title 
	}, 
	  
	handleCurrentChange(val){
	  this.pageNum = val;
	  this.newsListByChannelId();
	},
	  
	newsListByChannelId(){
	  let _this = this;
	  //根据栏目Id查询新闻列表信息
			if(this.childrenId==undefined){
				this.$axios.get('/api/article/channelAndNewsByChannelIdTop',{params:{channelId:this.channelId,pageNum:this.pageNum,pageSize:this.pageSize}}).then(response => {
				  let data = response.data.rows;
				  this.total = response.data.total;
				  this.newsListArr=data;
				});
			}else{
				//根据栏目Id查询新闻列表信息
				this.$axios.get('/api/article/newsByChannelList',{params:{channelId:this.childrenId,pageNum:this.pageNum,pageSize:this.pageSize}}).then(response => {
				  let data = response.data.rows;
				  this.total = response.data.total;
				  this.newsListArr=data;
				});
			}
			
	},
	
    channelChildrenById(){
      let _this = this;
      this.$axios.get('/api/article/channelChildrenById',{params:{channelId: this.channelId}}).then(response => {
        let data = response.data.data;
		if(this.childrenName==null){
			this.childrenName = data[0].title;
		}
        this.channelListArr=data;
		 //console.log(this.childrenName);
      });
    },
	
	newsByChannelId(channelId, channelName) {
      this.childrenName =channelName;
	  //根据栏目Id查询新闻列表信息
	  this.$axios.get('/api/article/newsByChannelList',{params:{channelId:channelId,pageNum:this.pageNum,pageSize:this.pageSize}}).then(response => {
	    let data = response.data.rows;
	    this.total = response.data.total;
	    this.newsListArr=data;
	  });
	  //let pathInfo = this.$router.resolve({path: '/subPage/zhdj/dj_list',query:{childrenId:channelId,childrenName:channelName}});
	  //console.log(this.pathInfo);
	  //window.open(pathInfo.href);
	  //this.flushCom();
    },
	
	flushCom:function(){
	  this.$router.go(0);  
	},

  }
};
</script>
<style>
</style>