<template>
  <div class="subPageContainer">
    <zy-top
      ref="zyTopRef"
      :curIndex="curIndex"
    ></zy-top>
    <div class="subBanner"><img :src="myimg" /></div>
    <div class="mainLayout subMainContent">
      <router-view />
    </div>

    <zy-foot></zy-foot>
  </div>
</template>
  
  <style>
</style>
  <script>
import top from "../../components/Top.vue";
import foot from "../../components/Foot.vue";
export default {
  data() {
    return {
      myimg: "",
      getBannerArr: [
        require("../../assets/images/subBan01.jpg"),
        require("../../assets/images/yw2.png"),
        require("../../assets/images/subBan03.jpg"),
        require("../../assets/images/subBan04.jpg"),
        require("../../assets/images/yw3.png"),
        require("../../assets/images/yw6.jpg"),
        require("../../assets/images/subBan05.jpg"),
      ],
      curIndex: 0,
    };
  },
  mounted() {
    this.getPath();
  },

  components: {
    zyTop: top,
    zyFoot: foot,
  },
  watch: {
    $route: "getPath",
  },
  methods: {
    getPath() {
      let curDom = this.$refs.zyTopRef.$el;
      if (this.$route.path === "/") {
        curDom.style.display = "none";
        this.curIndex = 0;
      } else {
        curDom.style.display = "block";
        /*   curDom.style.backgroundColor = "#f00"; */
      }
      switch (true) {
        case this.$route.path.includes("zjhyzh"):
          this.myimg = this.getBannerArr[0];
          this.curIndex = 1;
          break;
        case this.$route.path.includes("zjhwxjj"):
          this.myimg = this.getBannerArr[1];
          this.curIndex = 2;
          break;
        case this.$route.path.includes("zhdj"):
          this.myimg = this.getBannerArr[2];
          this.curIndex = 3;
          break;
        case this.$route.path.includes("gsyw"):
          this.myimg = this.getBannerArr[3];
          this.curIndex = 4;
          break;
        case this.$route.path.includes("cpzx"):
          this.myimg = this.getBannerArr[4];
          this.curIndex = 5;
          break;
        case this.$route.path.includes("jjfa"):
          this.myimg = this.getBannerArr[5];
          this.curIndex = 6;
          break;
        case this.$route.path.includes("lxwm"):
          this.myimg = this.getBannerArr[6];
          this.curIndex = 7;
          break;
      }
    },
  },
};
</script>
<style >
</style>
  