<template>
  <div class="prdCont">
    <div
      class='prodContent'
      v-for="item in newsListArr"
    >
      <div class="prodtext">
        <div class="prodName">
         <router-link :to="{path:'/subpage/cpzx/news_xq',query:{newsId:item.id}}"
          
         style="color: #3a7dd8;font-size: 30px;">
			<h3>{{item.newsTitle}}</h3>
         </router-link>
        </div>
        <div class="prodDes">
			{{item.daodu}}...
          <!-- 浙江省现代旅游产业研究院是浙江省旅游产业和全域旅游、旅游目的地的研究平台，旅游全产业链的研发创新平台，旅游项目及相关产品的研发创新平台。研究院集聚全国业内知名专家为研究院首席专家，设立浙旅现代旅游产业规划设计联盟，大胆探索产品创新、模式创新、业态创新，做到IP产品引领、发展模式引领、策划规划引领。 -->
        </div>
        <router-link :to="{path:'/subpage/cpzx/news_xq',query:{newsId:item.id}}" ><i class='el-icon-right'></i></router-link>
      </div>
      <router-link
        :to="{path:'/subpage/cpzx/news_xq',query:{newsId:item.id}}" 
        class="prodimg"
      >
        <img :src="item.imgUrl" />
      </router-link>
    </div>
	<div class="fenye">
		<el-pagination
		background
		  :total="total"
		  :page.sync="pageNum"
		  :limit.sync="pageSize"
		  :page-size="5"
		  @pagination="newsListByChannelId"
		  @current-change="handleCurrentChange"
		/>
	  <!-- <el-pagination
	    background
	    layout="prev, pager, next"
	    :total="1000"
	  >
	  </el-pagination> -->
	</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
		//栏目id
		channelId:7,
      //新闻列表
      newsListArr: [],
      // 总条数
      total: 0,
      // 查询参数
      pageNum: 1,
      pageSize: 5,
    };
  },
  mounted() {
    //根据栏目Id查询新闻列表信息
    this.newsListByChannelId();
  },
  watch: {},
  methods: {
    handleCurrentChange(val){
      this.pageNum = val;
      this.newsListByChannelId();
    },
    newsListByChannelId(){
      let _this = this;
      //根据栏目Id查询新闻列表信息
      this.$axios.get('/api/article/newsByChannelList',{params:{channelId:this.channelId,pageNum:this.pageNum,pageSize:this.pageSize}}).then(response => {
        let data = response.data.rows;
        this.total = response.data.total;
        this.newsListArr=data;
      });

    },

  }
};
</script>
<style>
.prdCont > .prodContent {
  height: 400px;
}
</style>