<template>
  <div class="Top_container">
    <div class="header mainLayout">
      <div class="logo wow animate__fadeInDown">
        洛阳弘义智慧城市建设服务有限公司
        <!-- <img src='../assets/images/logo.png' /> -->
      </div>
      <div class="mainMeau wow animate__fadeInDown">
        <div @click="sunMeauSwitch()">
          <i class="iconfont icon-ego-menu"></i>
        </div>
        <ul id="mainMeauId">
          <li :class="{'current':curIndex===0}">
            <router-link to="/">首页</router-link>
          </li>
          <li :class="{'current':curIndex===1}">
            <router-link to="/subpage/zjhyzh">走进弘义智慧</router-link>
          </li>
          <li :class="{'current':curIndex===2}">
            <router-link to="/subpage/zjhwxjj">走进华为新基建</router-link>
          </li>
          <li :class="{'current':curIndex===3}">
            <router-link to="/subpage/zhdj">智慧党建</router-link>
          </li>
          <li :class="{'current':curIndex===4}">
            <router-link to="/subpage/gsyw">企业公告</router-link>

          </li>
          <li :class="{'current':curIndex===5}">
            <router-link to="/subpage/cpzx">产品中心</router-link>

          </li>
          <li :class="{'current':curIndex===6}">
            <router-link to="/subpage/jjfa">解决方案</router-link>

          </li>
          <li :class="{'current':curIndex===7}">
            <router-link to="/subpage/lxwm">联系我们</router-link>
          </li>
		  <li :class="{'current':curIndex===8}">
		    <a href="http://oa.lyzhcs.com" target="_blank">OA</a>
		  </li>
          <div class="nav-line"></div>
        </ul>
      </div>
    </div>
    <el-drawer
      title="导航栏"
      :visible.sync="drawer"
      :with-header="true"
      size="180px"
      :modal-append-to-body="false"
    >
      <div class="subMeau">
        <ul>
          <li>
            <router-link to="/">首页</router-link>
          </li>
          <li>
            <router-link to="/subpage/zjhyzh">走进弘义智慧</router-link>
          </li>
          <li>
            <router-link to="/subpage/zjhwxjj">走进华为新基建</router-link>
          </li>
          <li>
            <router-link to="/subpage/zhdj">智慧党建</router-link>
          </li>
          <li>
            <router-link to="/subpage/gsyw">企业公告</router-link>

          </li>
          <li>
            <router-link to="/subpage/cpzx">产品中心</router-link>

          </li>
          <li>
            <router-link to="/subpage/jjfa">解决方案</router-link>

          </li>
          <li>
            <router-link to="/subpage/lxwm">联系我们</router-link>
          </li>
        </ul>
      </div>
    </el-drawer>
  </div>

</template>
<style >
.header {
  display: flex;
  z-index: 100;
  /*   position:absolute; */
  position: relative;
  /*  top: 20px; */
  /* left: 50%; */
  /*  transform: translate(-50%); */
  align-items: center;
  justify-content: space-between;
  /*  margin-bottom: 20px; */
  height: 84px;
}
.header .logo {
  font-size: 30px;
  display: flex;
  align-items: center;
  color: #3a7dd8;
  font-weight: bolder;
}
.Top_container {
  background-color: #fff;
}
.homeTopBJ {
  background: transparent;
}
.homeTopBJ .header {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}
.homeTopBJ .mainMeau ul li > a {
  color: #fff;
}
.homeTopBJ .mainMeau > div > i {
  color: #fff;
}
.mainMeau > div {
  display: none;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  padding-right: 20px;
  /* border: 1px solid red; */
}
.mainMeau > div > i {
  color: #333;
  font-size: 24px;
}

.mainMeau ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.mainMeau ul li {
  position: relative;
  text-align: center;
  z-index: 9999;
}

.mainMeau ul li > a {
  color: #333;
  font-size: 16px;
  height: 80px;
  line-height: 80px;
  display: block;
  padding: 0 10px;
}

.mainMeau .current a,
.mainMeau > ul > li > a:hover {
  color: #0150c6;
}
.mainMeau ul > li > div {
  background: rgba(255, 255, 255, 0.8);
  background-origin: border-box;
  display: none;
  width: 100%;
  top: 0px;
  padding-bottom: 20px;
  box-sizing: border-box;
  /*  border: 1px solid red; */
}
.mainMeau ul > li > div::before {
  display: block;
  height: 2px;
  content: "";
  background-image: linear-gradient(to right, #1c378b, #057dcc);
  margin-bottom: 10px;
}
.mainMeau ul > li > div > a {
  display: block;
  padding-top: 10px;
}
.mainMeau ul > li > div > a:hover {
  color: #057dcc;
}
.nav-line {
  height: 2px;
  width: 100%;
  margin: 0;
  padding: 0;
  background-image: linear-gradient(to right, #1c378b, #057dcc);
  position: absolute;
  top: 80px;
  z-index: -1;
}
/*小屏弹出菜单*/
.subMeau {
  z-index: 9999;
  padding: 10px;
}
.subMeau ul li {
  margin-bottom: 10px;
}
.subMeau ul li a {
  display: block;
  background-color: #1764ce;
  color: #fff;
  border-radius: 3rem;
  text-align: center;
  padding: 1rem 2rem;
}
</style>
<script>
export default {
  data() {
    return {
      drawer: false,
      scWidth: document.documentElement.clientWidth,
    };
  },
  props: ["curIndex"],
  mounted() {
    this.meauLineMove();
    window.addEventListener("resize", () => {
      this.scWidth = document.documentElement.clientWidth;

      if (this.scWidth > 1000) {
        this.drawer = false;
      }
    });
  },
  methods: {
    meauLineMove() {
      $(function () {
        var nav = $(".mainMeau");
        var navLine = nav.find(".nav-line");
        var defLineW = nav.find(".current").width();
        var defLineLeft = nav.find(".current").position().left;

        navLine.css({ left: defLineLeft, width: defLineW });
        nav.find("li").hover(
          function () {
            /*   $(this).children().css({
                  color: "#019cff",
                }); */

            var index = $(this).index();
            var curLineW = nav.find("li").eq(index).width();
            var curLineLeft = $(this).position().left;
            $(this).find("div").slideDown();
            //alert(curLineLeft);
            navLine.stop().animate(
              {
                left: curLineLeft,
                width: curLineW,
              },
              300
            );
          },
          function () {
            /*   $(this).children().css({
                  color: "#fff",
                }); */
            $(this).find("div").slideUp();
            navLine.stop().animate(
              {
                left: defLineLeft,
                width: defLineW,
              },
              300
            );
          }
        );
      });
    },
    sunMeauSwitch() {
      //关闭手机端菜单
      this.drawer = this.drawer == true ? false : true;
    },
  },
  watch: {
    curIndex() {
      this.meauLineMove();
    },
  },
};
</script>