<template>
  <div>
	  <div class="subPageContainer">
	    <zy-top
	      ref="zyTopRef"
	      :curIndex="3"
	    ></zy-top>
	    <div class="subBanner"><img :src="require('../../assets/images/subBan03.jpg')"/></div>
	    <div class="mainLayout subMainContent">
	      <!-- <router-view /> -->	
		  <div class="singleTitle">
		  	<h3>智慧党建</h3>
		  </div>
		  <div class="newsTitle">
		    <h3>
		      <!-- 新闻标题 -->
		  	{{newsDetail.newsTitle}}
		    </h3>
		    <p v-show="isShowTit">发布日期：{{newsDetail.createTime.substring(0,10)}} 浏览次数：{{newsDetail.click}}</p>
		  </div>
		  <div class="newCont">
		  	<p v-html="newsDetail.content"></p>
		    <!-- <p>深圳市昊创投资集团创立于1998年，注册资本2.8亿元，坐落于风景绮丽的梅林水库坝下，是一家传统产业与新兴产业相结合的综合性企业集团，涵盖不动产开发、工程建设、金融资本、健康养生、新兴产业等业务板块。昊创集团与多家大型企业建立战略合作伙伴关系，开展包括项目管理、工程施工、产业园开发运营、卫星通讯等在内的广泛合作。</p>
		    <p> 自创立以来，在陈少忠董事长的带领下，秉承“不断创新，追求卓越”的信念，昊创集团全体同仁锐意进取，敢于拼搏，勇于创新，取得了良好的经营业绩，打造了具有一定知名度的企业品牌，为深圳特区的发展和建设做出了应有的贡献。</p>
		    <img src="../../assets/images/banner01.jpg" /> -->
		  </div>
	    </div>
	    <zy-foot></zy-foot>
	  </div>
  </div>
</template>
<script>
	import PDFJSViewer from '../../components/PDFJSViewer';
	import top from "../../components/Top.vue";
	import foot from "../../components/Foot.vue";
export default {
	components: {
	    PDFJSViewer,
	    zyTop: top,
	    zyFoot: foot,
	  },
  data() {
    return {
      path: null,
      isShowTit: false,
	  //新闻id
	  newsId: 0,
	  //新闻信息
	  newsDetail: Object,
	  //pdf信息
	  name: '新闻.pdf',
	  path: null,
	  isPdfFlag: 0,
	  contentFlag: 0,
    };
  },
  watch: {
    $router: "getpath",
  },
  mounted() {
	  this.path = this.$route.path;
    if (this.path === "/subpage/gsyw/news_xq") {
      this.isShowTit = true;
    }
	//新闻id
	this.newsId= this.$route.query.newsId;
	//根据新闻id查询新闻详情
	this.newsDetailById();
  },
  methods: {
	  newsDetailById() {
	    this.$axios.get('/api/article/newsDetailById', {
	      params: {
	        newsId: this.newsId
	      }
	    }).then(response => {
	      let data = response.data.data;
	      this.newsDetail = data;
	      //获取是否pdf与pdfUrl
	      this.isPdfFlag = data.isPdf === 1 ? true : false;
	      this.contentFlag = !this.isPdfFlag;
	      this.path = this.newsDetail.pdfUrl;
	      this.channelId = data.channelId;
	      let click = data.click;
	      //根据当前新闻id与栏目id查询当前新闻的上一条
	      this.$axios.get('/api/article/newsDetailUp', {
	        params: {
	          newsId: this.newsId,
	          channelId: this.channelId
	        }
	      }).then(response => {
	        let data = response.data.data;
	        this.newsDetailUp = data;
	      });
	      //根据当前新闻id与栏目id查询当前新闻的下一条
	      this.$axios.get('/api/article/newsDetailNext', {
	        params: {
	          newsId: this.newsId,
	          channelId: this.channelId
	        }
	      }).then(response => {
	        let data = response.data.data;
	        this.newsDetailNext = data;
	      });
	      //根据当前新闻栏目id查询栏目信息
	      this.$axios.get('/api/article/channelByChannelId', {
	        params: {
	          channelId: this.channelId
	        }
	      }).then(response => {
	        let data = response.data.data;
	        let parentId = data.parentId;
	        if (0 == parentId) {
	          this.parentChannel = data;
	        } else {
	          this.channel = data;
	        }
	        //根据parentId查询父级栏目信息
	        if (0 != parentId) {
	          this.$axios.get('/api/article/channelByChannelId', {
	            params: {
	              channelId: parentId
	            }
	          }).then(response => {
	            let data = response.data.data;
	            this.parentChannel = data;
	          });
	        }
	      });
	      //更新点击量
	      let param = new URLSearchParams();
	      param.append("newsId", this.newsId);
	      param.append("click", click);
	      this.$axios({
	        method: "post",
	        // method:"get",
	        url: '/api/article/updateClickByNewsId',
	        data: param,
	      }).then((res) => {
	      });
	  
	  
	  
	    });
	  },
  },
};
</script>
<style>

</style>