import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/index.vue'   //首页全屏滚动
import SubPage from '../views/SubComponent/index.vue'   //子页面共用头和尾
import OnePage from '../views/SubPages/onePage.vue'  //走进弘义智慧
import TwoPage from '../views/SubPages/twoPage.vue'  //走进华为新基建
import ThreePage from '../views/SubPages/threePage.vue'  //智慧党建
import dj_list from '../views/SubComponent/dj_list.vue'  //智慧详情列表

import news_xq from '../views/SubComponent/news_xq.vue'  //详情
import dj_news_xq from '../views/SubComponent/dj_news_xq.vue'  //党建新闻详情



import FourPage from '../views/SubPages/fourPage.vue'  //企业公告
import gsyw_list from '../views/SubComponent/gsyw_list.vue'  //企业公告列表




import FivePage from '../views/SubPages/fivePage.vue'  //产品中心
import cpzx_list from '../views/SubComponent/cpzx_list.vue'  //产品中心列表


import SixPage from '../views/SubPages/sixPage.vue'  //解决方案
import jjfa_list from '../views/SubComponent/jjfa_list.vue'  //解决方案列表

import SevenPage from '../views/SubPages/sevenPage.vue'  //联系我们
Vue.use(VueRouter)



const routes = [
    /*    {
           path: '/',
           component: Home,
   
       }, */
    {
        path: '/',
        component: Home,
    },
	{
		path: '/dj_news_xq',
		component: dj_news_xq,
	},
    {
        path: '/subpage',
        component: SubPage,
        children: [
            {
                path: 'zjhyzh',
                component: OnePage,
            },
            {
                path: 'zjhwxjj',
                component: TwoPage,
            },
            {
                path: 'zhdj/',
                component: ThreePage,
                redirect: 'zhdj/dj_list', 
                children: [
                    {
                        path: 'dj_list',
                        component: dj_list,

                    },
                    // {
                    //     path: 'news_xq',
                    //     component: news_xq,

                    // },
                ]
            },
            {
                path: 'gsyw',
                component: FourPage,
                redirect: 'gsyw/gsyw_list',
                children: [
                    {
                        path: 'gsyw_list',
                        component: gsyw_list,

                    },
                    {
                        path: 'news_xq',
                        component: news_xq,

                    }
                ]

            },
            {
                path: 'cpzx',
                component: FivePage,
                redirect: 'cpzx/cpzx_list',
                children: [
                    {
                        path: 'cpzx_list',
                        component: cpzx_list,

                    },
                    {
                        path: 'news_xq',
                        component: news_xq,

                    }
                ]
            },
            {
                path: 'jjfa',
                component: SixPage,
                redirect: 'jjfa/jjfa_list',
                children: [
                    {
                        path: 'jjfa_list',
                        component: jjfa_list,

                    },
                    {
                        path: 'news_xq',
                        component: news_xq,

                    }
                ]
            },
            {
                path: 'lxwm',
                component: SevenPage,
            }
        ]

    },
    /*     {
            path: '/qydt/:id',
            component: Qydt,
            props: true
    
        },
        {
            path: '/newsxq/:id',
            component: newsXq,
            props: true
    
        },
        {
            path: '/qywh/:id',
            component: Qywh,
            props: true
        },
        {
            path: '/zshz/:id',
            component: Zshz,
            props: true
        },
        {
            path: '/ywly/:id',
            component: Ywly,
            props: true
        },
        {
            path: '/rlzy',
            component: Rlzy,
        }, */
]

const router = new VueRouter({
	mode: 'history',
    routes
})
Vue.use(VueRouter)
export default router
