<template>
  <!-- Swiper -->
  <div class="HomeContainer">
   <swiper
      :options="swiperOption"
      ref="mySwiper"
    >
      <!--  第一屏banner轮播图开始 -->
      <swiper-slide>
        <div class="bannerContainer">
          <zy-top
            class="homeTopBJ"
            :curIndex="curIndex"
          ></zy-top>
          <!--粘贴代码开始-->
          <swiper
            :options="bannerSwiperOption"
            ref="mySwiper"
            :style="{height:scrH}"
          >
            <swiper-slide
              v-for="(item,index) in ListBanner"
              :key="index"
            >

              <div class='bg-full'>
                <img :src='item.url' />
                <div
                  class="fullText"
                  style="width:1500px"
                >
                  <h1>
                    {{item.h1Text}}
                  </h1>
                  <h2>
                    {{item.h2Text}}
                  </h2>
                  <p>
                    <router-link :to="item.h3Link" >了解更多</router-link>
                  </p>
                </div>
              </div>
            </swiper-slide>

            <div
              class="swiper-pagination"
              slot="pagination"
              v-if="ListBanner.length > 1"
            ></div>

           <!-- <div
              class="swiper-button-prev wow animate__fadeInLeft"
              v-if="ListBanner.length > 1"
              slot="button-prev"
            ></div>
            <div
              class="swiper-button-next wow animate__fadeInRight"
              v-if="ListBanner.length > 1"
              slot="button-next"
            ></div> -->

          </swiper>
          <!--粘贴代码结束-->
        </div>
      </swiper-slide>
      <!-- 第一屏banner轮播图结束 -->
      <!--  第二屏走进弘义智慧开始 -->
      <swiper-slide>
        <div class="jianjieContainer">
          <div class="titleBox">
            <h3>
              <span>走进</span> <span>弘义智慧</span>
            </h3>
            <p>ZOUJIN HONGYI ZHIHUI</p>
          </div>
          <div class="contentBox mainLayout">
            <div class="video_left">
				<video controls style="width:640px;height: 360px;">
				    <source src="../../assets/video01.mp4" type="video/mp4">
				    您的浏览器不支持 video 标签。
				</video>
              <!-- <img src="../../assets/images/videobj.png" />
              <router-link to="/"><img src='../../assets/images/play.png' /></router-link> -->
            </div>
            <div class="gstext_right">
              <h3>洛阳弘义智慧<p>城市建设服务有限公司</p>
              </h3>
			  <p>{{newsDetail.daodu}}...</p>
              <!-- <p>作为新基建项目的实施方及运营公司，通过华为大数据和云服务产品，为区内工业、软件开发、交通、教育、医疗等行业用户提供云服务资源供其使用，牵引区内新基建相关产业发展，减低信息化成本，实现创新转型发展，助力企业云上转型。推动产业提质增效快速发展。惯彻落实党中央，国务院建设制造强国、发展新能源、壮大实体经济、推进高质量发展的指示精神...</p> -->
              <router-link v-bind:title="newsDetail.newsTitle" to="/subpage/zjhyzh" ><span>了解更多</span><i class='el-icon-arrow-right
'></i></router-link>
            </div>
          </div>

        </div>
      </swiper-slide>
      <!--  第二屏走进弘义智慧结束 -->
      <!--  第三屏企业公告开始 -->
      <swiper-slide>
        <div class="indexnewsContainer">
          <div class="titleBox">
            <h3>
              <span>企业</span> <span>公告</span>
            </h3>
            <p>QIYE GONGGAO</p>
          </div>
          <div class="newsContent mainLayout">
            <ul class="indexNewsul">
              <li v-for="item in newsListArr">
                <div class="newsData">
                  <h1>{{item.createTime.substring(8,10)}}</h1>
                  <p>{{item.createTime.substring(0,7)}}</p>
                </div>
                <div class="newsText">
                  <router-link :to="{path:'/subpage/gsyw/news_xq',query:{newsId:item.id}}" :title="item.newsTitle"><em>{{item.newsTitle | ellipsis15}}</em></router-link>
                  <router-link :to="{path:'/subpage/gsyw/news_xq',query:{newsId:item.id}}" ><img :src='item.newsImg' :title="item.newsTitle"/></router-link>
                  <p>
                    {{item.newsDes}}
                  </p>
                  <router-link :to="{path:'/subpage/gsyw/news_xq',query:{newsId:item.id}}" ></router-link>
                </div>
              </li>
            </ul>
            <div class="moreBox">
              <router-link
                to="/subpage/gsyw"
                class="moreBut" 
              ><span>了解更多</span><i class='el-icon-arrow-right'></i></router-link>
            </div>

          </div>
        </div>
      </swiper-slide>
      <!--  第三屏企业公告结束 -->
      <!--  第四屏产品中心开始 -->
      <swiper-slide >
        <div class="prodContainer">
          <div class="titleBox">
            <h3>
              <span>产品</span> <span>中心</span>
            </h3>
            <p>CHANPIN ZHONGXIN</p>
          </div>
          <div class="mainLayout">
            <swiper
              :options="prdSwiperOption"
              ref="mySwiper"
			  
            >
              <swiper-slide
                v-for="(item,index) in productListArr"
                :key="item.id" 
				
              >
                <div class='prodContent'>

                  <div class="prodtext">
                    <div class="prodName">
					  <router-link :to="{path:'/subpage/cpzx/news_xq',query:{newsId:item.id}}" :title="item.newsTitle"
					  style="color: #3a7dd8;font-size: 30px;">
						  <h3>{{item.newsTitle}}</h3>
					  </router-link>
                      <!-- <p>Smart City</p> -->
                    </div>
                    <!-- <div class="prodDes">
                      浙江省现代旅游产业研究院是浙江省旅游产业和全域旅游、旅游目的地的研究平台，旅游全产业链的研发创新平台，旅游项目及相关产品的研发创新平台。研究院集聚全国业内知名专家为研究院首席专家，设立浙旅现代旅游产业规划设计联盟，大胆探索产品创新、模式创新、业态创新，做到IP产品引领、发展模式引领、策划规划引领。
                    </div> -->
					<div class="prodDes">
						{{item.daodu}}...
					</div>
                    <router-link :to="{path:'/subpage/cpzx/news_xq',query:{newsId:item.id}}" ><i class='el-icon-right'></i></router-link>
                  </div>
                  <router-link
                    :to="{path:'/subpage/cpzx/news_xq',query:{newsId:item.id}}" 
                    class="prodimg"
                  >
                    <img :src="item.imgUrl" :title="item.newsTitle"/>
                  </router-link>
                </div>

              </swiper-slide>
              <div
                class="prd-swiper-pagination"
                slot="pagination"
              ></div>
            </swiper>

          </div>
          <div class="moreBox">
            <router-link
              to="/subpage/cpzx"
              class="moreBut"
            ><span>了解更多</span><i class='el-icon-arrow-right'></i></router-link>
          </div>
        </div>
      </swiper-slide>
      <!--  第四屏产品中心结束 -->
      <!--  第五屏解决方案开始 -->
      <swiper-slide>
        <div class="fanganContainer">
          <div class="titleBox">
            <h3>
              <span style="color:#fff">解决</span><span>方案</span>
            </h3>
            <p style="color:#1b253b">JIEJUE FANGAN</p>
          </div>
          <div class="fanganCont mainLayout">
            <div
              class="fanganItem"
              v-for="(item,index) in planListArr"
              :key="item.id"
			  @click="goTail(item.id)"
            >
				<img :src="item.imgUrl"/>
              
			  <div>
                <span><i class='el-icon-files'></i></span>
                <h3>{{item.newsTitle}}</h3>
                <router-link :to="{path:'/subpage/jjfa/news_xq',query:{newsId:item.id}}" >查看更多<i class='el-icon-plus'></i></router-link>
              </div>
            </div>
            <!-- <div
              class="moreFangan"
              @click="$router.push('/subpage/jjfa')"
			  target="_blank"
            > -->
			<router-link to="/subpage/jjfa" class="moreFangan" ><span><i class="el-icon-right"></i></span> <p> 更多解决方案</p></router-link>
             <!-- <router-link to="/subpage/jjfa" target="_blank"><i class="el-icon-right"></i></router-link> -->
              <!-- <p> 更多解决方案</p> -->
            </div>

          <!-- </div> -->
        </div>
      </swiper-slide>
      <!--  第五屏解决方案结束 -->
      <!--  第六屏联系我们开始 -->
      <swiper-slide>
        <div class="contactUsContainer">
          <div class="titleBox">
            <h3>
              <span>联系</span><span>我们</span>
            </h3>
            <p>LIANXI WOMEN</p>
          </div>
          <div class="mainLayout">
           <div id='container' class="mapBox">
           		<div class="mapBox">
               <!-- <img :src="aboutDetail.addressImg"/> -->
             </div>
           </div>
            <div class="lxText">
              <div>
                <span>
                  <i class="el-icon-map-location"></i>
                  <h3>地址</h3>
                </span>
                <p>{{aboutDetail.address}}</p>
              </div>
              <div>
                <span>
                  <i class="el-icon-phone-outline"></i>
                  <h3>电话</h3>
                </span>
                <p>{{aboutDetail.phone}}</p>
              </div>
              <div>
                <span>
                  <i class="el-icon-message"></i>
                  <h3>邮箱</h3>
                </span>
                <p>{{aboutDetail.email}}</p>
              </div>
            </div>
			</div>
        </div>
      </swiper-slide>

      <!--  第六屏联系我们结束 -->
      <!--  第七屏合作伙伴开始 -->
      <swiper-slide style="overflow-y:auto">
        <div class="coopContainer">
          <div class="titleBox">
            <h3>
              <span>合作</span><span>伙伴</span>
            </h3>
            <p style="color:#becae2">HEZUO HUOBAN</p>
          </div>
          <div class="mainLayout">
            <ul class="hzUl">
              <li v-for="item in imgLinkListArr"><a :href="item.linkUrl" :title="item.title" ><img :src="item.imgUrl" /></a></li>
            </ul>
          </div>
		  
		<!--   <zy-foot></zy-foot> -->
        </div>
      <zy-foot></zy-foot>
      </swiper-slide>
	  
      <!--  第七屏合作伙伴结束 -->
  <!--   <swiper-slide>
        <zy-foot></zy-foot>
      </swiper-slide> -->

      <!--第八屏 底部链接等-->
   </swiper>
    <!-- 首页滚动条 -->
       <div class="swiper-scrollbar"></div>
   
   

  </div>

</template>
<script>
import WOW from "wow.js";
import top from "../../components/Top.vue";
import foot from "../../components/Foot";
import AMapLoader from '@amap/amap-jsapi-loader';
import { swiper, swiperSlide } from "vue-awesome-swiper";
var vm = null;
export default {
  name: "mapDemo",
  data() {
    return {
      scrH: "", //屏幕宽度
      curIndex: 0, //要传给top组件的索引
      swiperOption: {
		   //其他设置
		   direction: "vertical",
		   autoHeight: true,
		   height: window.innerHeight,
		   effect: "slide",
		   observer: true,
		   observeParents: true,
		   mousewheel: true,
		  allowTouchMove:false,
   
		scrollbar: {
		   el: '.swiper-scrollbar',
		   direction:'vertical',
		   dragSize: 140,
		   draggable: true,
		   snapOnRelease: true,
		   hide: false  //不让滚动条自动隐藏
		  },
      },
      ListBanner: [],
	  //走进弘义
	  newsDetail:Object,
	  
      bannerSwiperOption: {
        //显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
        //设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        //自动轮播
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        //开启循环模式
        loop: true,
        //开启鼠标滚轮控制Swiper切换
        /*   mousewheel: true, */
        effect: "fade",
        observer: true,
        observeParents: true,
        on: {
          slideChangeTransitionStart: function () {
            //切换结束时，告诉我现在是第几个slide
            var _checkDOM = document.querySelectorAll(".bg-full .fullText");
            var ads = Array.from(_checkDOM[this.activeIndex].children);

            ads.forEach((adsItem) => {
              adsItem.className = "";
            });
            setTimeout(function () {
              ads.forEach((adsItem, ind) => {
                adsItem.className = "animate__animated  animate__fadeInUp";
                adsItem.style.animationDelay = vm.wowDelay(ind, 0.5);
              });
            }, 0);
          },
        },
      },
      prdSwiperOption: {
        //显示分页
        pagination: {
          el: ".prd-swiper-pagination",
          clickable: true, //允许分页点击跳转
          /*  bulletClass: "my-bullet",
          bulletActiveClass: "my-bullet-active", */
        },
        //自动轮播
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
		//开启循环模式
		loop: true,
		//开启鼠标滚轮控制Swiper切换
		/*   mousewheel: true, */
		effect: "fade",
		observer: true,
		observeParents: true,
  //       //开启循环模式
  //       loop: true,
		// // allowTouchMove:false,
  //       //开启鼠标滚轮控制Swiper切换
  //       /*   mousewheel: true, */
  //       /*        effect: "fade", */
  //       observer: true,
  //       observeParents: true,
      },
	  //企业公告
      newsListArr: [],
	  //产品中心
	  productListArr:[],
	  //解决方案
	  planListArr:[],
	  //联系我们
	  aboutDetail:Object,
	  //合作伙伴
	  imgLinkListArr:[],
	  
	  
    };
  },
  created() {
    vm = this;
	//首页轮播新闻
	this.imgNewsLimit();
	//企业公告
	this.newsListByChannelIdLimit();
	//产品中心
	this.productListByChannelIdLimit();
  },
  mounted() {
	  this.updatepvuv();
	//DOM初始化完成进行地图初始化
	this.initMap();
	//走进弘义智慧
	this.newsDetailByChannelId(3);
	//解决方案
	this.planListByChannelIdLimit();
	//联系我们
	this.usDetail();
	//合作伙伴
	this.imgLinkList();
    this.scrH = document.documentElement.clientHeight + "px";
    var _this = this;
    window.addEventListener("resize", function () {
      _this.scrH = document.documentElement.clientHeight + "px";
    });
    this.getPath();
    var wow = new WOW({
      boxClass: "wow", // animated element css class (default is wow)
      animateClass: "animate__animated", // animation css class (default is animated)
      offset: 0, // distance to the element when triggering the animation (default is 0)
      mobile: true, // trigger animations on mobile devices (default is true)
      live: true, // act on asynchronously loaded content (default is true)
      scrollContainer: null, // optional scroll container selector, otherwise use window,
      resetAnimation: true, // reset animation on end (default is true)
    });
    wow.init();
  },
  components: { swiper, swiperSlide, zyTop: top, zyFoot: foot },

  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  filters: {
      ellipsis15(value) {
        if (!value) return "";
        if (value.length > 26) {
          return value.slice(0, 26) + "...";
        }
        return value;
      },
  },
  methods: {
	  
	  //点击进入解决详情页面
	  goTail(newsId){
		  // path:'/subpage/jjfa/news_xq',query:{newsId:item.id
		  this.$router.push({path:'/subpage/jjfa/news_xq',query:{newsId:newsId}})
	  },
	  
	  //地图初始化函数 initMap
	  initMap(){
	    AMapLoader.load({
	        key:"23775456e132427d68a9d5fa0db7ac02",             // 申请好的Web端开发者Key，首次调用 load 时必填
	        version:"1.4.15",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
	        plugins:['AMap.Scale','AMap.ToolBar'],// 需要使用的的插件列表，如比例尺'AMap.Scale'等
	    }).then((AMap)=>{
	      setTimeout(() => {
				  //渲染地图
				  var map = new AMap.Map("container",{  //设置地图容器id
					  zoom:15,//设置地图显示的缩放级别
					  expandZoomRange:true,
					  resizeEnable: true,
					  zooms:[5,30],
					  center: [112.471028, 34.628236], //中心点坐标
					  zoomEnable: true,
					  resizeEnable: true,
					  scrollWheel: false, //鼠标滚动放大缩小地图  默认true
					  //mapStyle: "amap://styles/fresh"
				  });
				  // 创建一个 Marker 实例：
				  var marker = new AMap.Marker({
				      position: new AMap.LngLat(112.471028,34.628236),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
					  offset: new AMap.Pixel(0, 0)
				  });
				  //工具条控件、比例尺控件、鹰眼控件
				  map.addControl(new AMap.ToolBar());
				  map.addControl(new AMap.Scale());
				  
				  // 设置鼠标划过点标记显示的文字提示
				  marker.setTitle('洛阳弘义智慧城市建设服务有限公司');
				  // label默认蓝框白底左上角显示，样式className为：amap-marker-label
				  marker.setLabel({
					  direction:'center',
					  offset: new AMap.Pixel(0, -35),  //设置文本标注偏移量
					  content: "<div class='info' style='color:#1874CD; font-weight:bolder'>洛阳弘义智慧城市建设服务有限公司</div>", //设置文本标注内容
				  });
				  map.add(marker); //添加到地图
				  
				
	      }, 1000);
	    }).catch(e=>{
	    });
	  
	  },
	  
	  
	  /** 查询首页轮播单篇新闻 */
	  imgNewsLimit() {
	    let _this = this;
	    this.$axios
	      .get("/api/article/newsLimit", {
	        params: {
	          limit: 2,
	        },
	      })
	      .then((response) => {
	        let data = response.data.data;
	  	  for(let i=0;i<data.length;i++){
	  		  
	  		  let item={};
	  		  item["url"]=data[i].imgUrl;
	  		  item["h1Text"]=data[i].channel.title;
	  		  item["h2Text"]=data[i].daodu;
	  		  item["h3Link"]="subpage/zjhyzh?newsId="+data[i].id;
	  		  this.ListBanner[i]=item;
	  	  }
	      });
	  },
	  
	  newsDetailByChannelId(channelId) {
	    //根据当前栏目id查询单篇新闻
	    this.$axios
	      .get("/api/article/newsDetailByChannelId", {
	        params: {
	          channelId: channelId,
	        },
	      })
	      .then((response) => {
	        let data = response.data.data[0];
	        this.newsDetail = data;
	      });
	  },
	  //企业公告
	  newsListByChannelIdLimit() {
	    let _this = this;
	    let channelId = 6;
	    let limit = 4;
	    this.$axios
	      .get("/api/article/newsByChannelIdTopLimit", {
	        params: {
	          channelId: channelId,
	          limit: limit,
	        },
	      })
	      .then((response) => {
	        let data = response.data.data;
			for(let i=0;i<data.length;i++){
	  		  let item={};
			  item["id"]=data[i].id;
			  item["createTime"]=data[i].createTime;
			  item["newsTitle"]=data[i].newsTitle;
	  		  item["newsImg"]=data[i].imgUrl;
	  		  item["newsDes"]=data[i].daodu;
	  		  this.newsListArr[i]=item;
	  	  }
	      });
	  },
	  //产品中心
	  productListByChannelIdLimit() {
	    let _this = this;
	    let channelId = 7;
	    let limit = 4;
	    this.$axios
	      .get("/api/article/newsByChannelIdTopLimit", {
	        params: {
	          channelId: channelId,
	          limit: limit,
	        },
	      })
	      .then((response) => {
	        let data = response.data.data;
			this.productListArr=data;
	      });
	  },
	  //解决方案
	  planListByChannelIdLimit() {
	    let _this = this;
	    let channelId = 8;
	    let limit = 5;
	    this.$axios
	      .get("/api/article/newsByChannelIdTopLimit", {
	        params: {
	          channelId: channelId,
	          limit: limit,
	        },
	      })
	      .then((response) => {
	        let data = response.data.data;
	  			this.planListArr=data;
	  			
	      });
	  },
	  usDetail() {
	    this.$axios.get("/api/us/1").then((response) => {
	      this.aboutDetail= response.data.data;
	    });
	  },
	  //合作伙伴
	  imgLinkList() {
	    let _this = this;
	    this.$axios.get("/api/link/imgList", {}).then((response) => {
	      this.imgLinkListArr = response.data.rows;
	    });
	  },
    wowDelay(ind, deftime) {
      //wowjs  执行的推迟时间  循环场景使用 ind是索引   deftime 推迟时间基准值
      var num = deftime + 0.5 * ind;
      var tim = `${num}s`;
      return tim;
    },
    getPath() {
      switch (true) {
        case this.$route.path.includes("zjhyzh"):
          this.curIndex = 1;
          break;
        case this.$route.path.includes("zjhwxjj"):
          this.curIndex = 2;
          break;
        case this.$route.path.includes("zhdj"):
          this.curIndex = 3;
          break;
        case this.$route.path.includes("gsyw"):
          this.curIndex = 4;
          break;
        case this.$route.path.includes("cpzx"):
          this.curIndex = 5;
          break;
        case this.$route.path.includes("jjfa"):
          this.curIndex = 6;
          break;
        case this.$route.path.includes("lxwm"):
          this.curIndex = 7;
          break;
      }
    },
	updatepvuv() {
	  this.$axios.post("/api/pvuv/update").then((response) => {});
	},
  },
  watch: { $route: "getPath" },
};
</script>
<style >
em {
	font-style: normal;
}

.HomeContainer > .swiper-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.HomeContainer > .swiper-container > .swiper-wrapper > .swiper-slide {
  background-color: #f7f7f7;
  height: 100%;
}

/* 第一屏banner轮播 */
.bannerContainer {
  position: relative;
  height: 100%;
}
.bg-full {
  display: flex;
  box-sizing: border-box;
  position: relative;
  text-align: left;
  height: 100vh;
  align-items: center;
}
.bg-full > img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  image-rendering: -webkit-optimize-contrast;
}
.bg-full .fullText {
  width: 1500px;
  margin: 0 auto;
}
.bg-full .fullText > h1 {
  font-size: 60px;
  font-weight: bold;
  color: #fff;
}
.bg-full .fullText > h2 {
  color: #fff;
  font-size: 16px;
  margin: 30px 0;
  font-weight: 200;
}
.bg-full .fullText > p > a {
  display: inline-block;
  color: #fff;
  font-size: 14px;
  padding: 10px 24px;
  font-weight: 200;
  background-color: #3a7dd8;
  border-radius: 4px;
}
.bannerContainer .swiper-button-next,
.bannerContainer .swiper-button-prev {
  display: block;
}
.bannerContainer .swiper-button-next {
  right: 30px !important;
}
.bannerContainer .swiper-button-next:after,
.bannerContainer .swiper-button-prev:after {
  content: "" !important;
  display: block;
  width: 46px !important;
  height: 38px !important;
}
.bannerContainer .swiper-button-prev:after {
  background: url("../../assets/images/left-arrow.png");
}
.bannerContainer .swiper-button-next:after {
  background: url("../../assets/images/right-arrow.png");
}

/*第二屏走进弘义智慧 */
.jianjieContainer {
  height: 100%;
  background: url("../../assets/images/bowen.png") right bottom no-repeat
    #f5f6fc;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.titleBox {
  text-align: center;
}
.titleBox > h3 {
  font-size: 48px;
  position: relative;
  bottom: -30px;
}
.titleBox > h3 > span:nth-child(1) {
  color: #333;
}
.titleBox > h3 > span:nth-child(2) {
  color: #3a7dd8;
}
.titleBox > p {
  color: #e3e4e5;
  font-size: 36px;
  z-index: -1;
}
.contentBox {
  display: flex;
  gap: 30px;
  align-items: center;
}
.contentBox .video_left {
  background-color: #303c56;
  /* padding: 60px 0; */
  position: relative;
}
.contentBox .video_left > a {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.contentBox .gstext_right > h3 {
  font-size: 48px;
  font-weight: 100;
  margin-bottom: 40px;
}
.contentBox .gstext_right > h3 > p {
  color: #3a7dd8;
  font-size: 30px;
}
.contentBox .gstext_right > p {
  font-size: 14px;
  line-height: 1.8em;
}
.contentBox .gstext_right > a {
  background-color: #3a7dd8;
  border-radius: 6px;
  padding: 10px 0;
  width: 120px;
  margin-top: 30px;
  color: #fff;
  font-size: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0;
  transition: all 0.5s;
}
.contentBox .gstext_right > a:hover {
  gap: 10px;
}

/*第三屏企业公告 */
.indexnewsContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #fff;
}
.indexNewsul {
  display: flex;
  width: 100%;
}
.indexNewsul > li {
  border-right: 1px solid #dfdfdf;
  width: 25%;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: left;
  background-color: #fff;
  transition: all 0.5s;
}
.indexNewsul > li > .newsData {
  margin-bottom: 60px;
  font-family: "微软雅黑";
  color: #555;
}
.indexNewsul > li > .newsData > h1 {
  font-size: 48px;
  font-weight: 100;
  line-height: 48px;
}
.indexNewsul > li > .newsData > p {
  font-size: 14px;
}

.indexNewsul > li > .newsText > a:nth-child(1) {
  height: 55px;
  font-size: 16px;
  display: block;
  line-height: 1.8em;
  /* white-space: nowrap; */
  overflow: hidden;
  /* text-overflow: ellipsis; */
/* display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden; */
}
.indexNewsul > li > .newsText > a:nth-child(1):hover {
  color: #3a7dd8;
}
.indexNewsul > li > .newsText > a:nth-child(2) {
  display: block;
  margin: 30px 0;
  width: 100%;
  height: 228px;
  overflow: hidden;
}
.indexNewsul > li > .newsText > a:nth-child(2) > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  image-rendering: -webkit-optimize-contrast;
  transition: all 0.5s;
  opacity: 1;
}
.indexNewsul > li:hover > .newsText > a:nth-child(2) > img {
  transform: scale(1.05);
  opacity: 0.8;
}
.indexNewsul > li > .newsText > p {
  font-size: 14px;
  line-height: 1.8em;
}
.indexNewsul > li > .newsText > a:nth-child(4) {
  background: url("../../assets/images/ico_02.png") center right no-repeat;
  width: 31px;
  height: 10px;
  display: inline-block;
  transition: all 0.5s;
  margin-top: 30px;
  display: block;
}
.indexNewsul > li > .newsText > a:nth-child(4):hover {
  width: 80px;
}

.indexNewsul > li:hover > .newsText > a:nth-child(4) {
  width: 80px;
}

.moreBox {
  display: flex;
  justify-content: center;
}
.moreBox .moreBut {
  background-color: #3a7dd8;
  width: 180px;
  padding: 16px 0;
  border-radius: 6px;
  color: #fff;
  font-size: 20px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0;
  transition: all 0.5s;
}
.moreBox .moreBut:hover {
  gap: 10px;
  background-color: #478ae5;
}
/* 第四屏产品中心 */
.prodContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 40px;
  background-color: #f7f7f7;
  /*  background: url("../../assets/images/subBan06.jpg") no-repeat 0 0;
  background-size: cover; */
}
.prodContent {
  display: flex;
  height: calc(100vh - 400px);
  padding-bottom: 20px;
}
.prodContent .prodtext {
  width: 50%;
  background-color: #fff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  padding: 80px 60px;
  box-sizing: border-box;
}
.prodtext > a {
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.prodtext > a > i {
  font-size: 16px;
  color: #999;
}
.prodtext > a:hover {
  background-color: #f7f7f7;
}
.prodtext > a:hover > i {
  color: #333;
}
.prodName {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  margin-bottom: 30px;
}
.prodName > h3 {
  color: #3a7dd8;
  font-size: 30px;
}
.prodName > p {
  color: #ddd;
  font-size: 16px;
}
.prodDes {
  font-size: 16px;
  line-height: 2em;
  color: #7f7f7f;
  margin-bottom: 30px;
}
.prodContent .prodimg {
  flex: 1;
  overflow: hidden;
  height: 100%;
}
.prodContent .prodimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s;
  opacity: 1;
}
.prodContent .prodimg:hover img {
  transform: scale(1.05);
  opacity: 0.8;
}
.prd-swiper-pagination {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.prodContainer /deep/ .swiper-pagination-bullet {
  width: 30px !important;
  height: 4px !important;
  border-radius: 20px !important;
  background: #b8b8b8 !important;
  opacity: 0.5 !important;
}

.prodContainer /deep/ .swiper-pagination-bullet-active {
  background: #007aff !important;
  opacity: 1 !important;
}
/*第五屏解决方案 */
.fanganContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 40px;
  background-color: #303c56;
}
.fanganCont {
  display: flex;
  flex-wrap: wrap;
}
.fanganCont > .fanganItem {
  width: 33.3333%;
  height: 300px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
.fanganCont > .fanganItem > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  image-rendering: -webkit-optimize-contrast;
}
.fanganCont > .fanganItem > div {
  position: absolute;

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 30px;
  color: #fff;
  padding-top: 40px;
  box-sizing: border-box;
  background: rgba(0,0,0,.4);
}
.fanganCont > .fanganItem:hover > div {
 /* background-color: #3485ee; */
 background: rgba(0,0,0,.2);
  padding-top: 0px;
  cursor: pointer;
}
.fanganCont > .fanganItem > div > span > i {
  font-size: 60px;
}
.fanganCont > .fanganItem > div > h3 {
  font-size: 24px;
}
.fanganCont > .fanganItem > div > a {
  border: 1px solid #fff;
  padding: 8px 34px;
  color: #fff;
  font-size: 16px;
  position: relative;
  transform: translateY(100px);
  transition: all 0.5s;
  border-radius: 6px;
}
.fanganCont > .fanganItem > div > a:hover {
  background-color: #4190f5;
  border: 1px solid #7ab3fb;
  color: #f6c621;
}
.fanganCont > .fanganItem:hover > div > a {
  transform: translateY(0);
  margin-top: 50px;
}

.moreFangan {
  background-color: #f7f7f7;
  width: 33.3333%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  transition: all 0.5s;
}
.moreFangan:hover {
  gap: 20px;
}
.moreFangan > span {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 50%;
}
.moreFangan > span > i {
  font-size: 24px;
}
.moreFangan > p {
  font-size: 16px;
}

/* 第六屏联系我们 */
.contactUsContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 40px;
  background-color: #f7f7f7;
}
.mapBox {
  background: linear-gradient(to right, #27a9e0 0%, #43c6ac 30%, #f6d30e 100%);
  margin-bottom: 30px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  overflow: hidden;
  height: 380px;
}
.mapBox > img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  image-rendering: -webkit-optimize-contrast;
}
.lxText {
  display: flex;
  gap: 20px;
}
.lxText > div {
  background-color: #fff;
  border-radius: 6px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  gap: 40px;
  font-size: 16px;
  transition: all 0.5s;
}
.lxText > div:hover {
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1);
  background-color: #f5f9ff;
}
.lxText > div > span > i {
  font-size: 48px;
  color: #000;
  font-weight: 100;
}
.lxText > div > span > h3 {
  font-size: 24px;
  color: #000;
  text-align: center;
}

/*第七屏合作伙伴 */
.coopContainer {
  height: 67%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: url("../../assets/images/hzbj.png") right bottom no-repeat,
    linear-gradient(to right, #edf2f8, #d5dcea);
 /* padding-bottom: 40px; */
  gap: 40px;
}
.hzUl {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.hzUl li {
  width: calc(20% - 8px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
}
.hzUl li img {
  max-width: 100%;
  max-height: 100%;
  object-fit: fill;
  image-rendering: -webkit-optimize-contrast;
}

.mainLayout .swiper-container .swiper-pagination-bullet{
	width: 20px;
	height: 5px;
	border-radius: 3px;
}


/* 
滚动条的条条 */
.HomeContainer .swiper-scrollbar {
 
    width: 10px;
    border-radius: 0;
    right: 0;
    top: 0;
    background-color: #f1f1f1;
    position: absolute;
    z-index: 33;
    height: 100%;
}
.HomeContainer .swiper-scrollbar-drag {
    width: 10px;
    position: absolute;
    left: 0px;
    top: 0;
    background: #c1c1c1;
    border-radius:0px;  
    
}

.amap-marker-label {
	position: absolute;
	z-index: 2;
	border: 1px solid blue;
	background-color: white;
	white-space: nowrap;
	cursor: default;
	padding: 3px;
	font-size: 20px;
	line-height: 22px;
}




</style>