<template>
  <div>
    <div class="singleTitle">
      <h3>联系我们</h3>
    </div>
    <div class="mainLayout">
		  <div id='container' class="mapBox">
			<div class="mapBox">
        <!-- <img :src="aboutDetail.addressImg"/> -->
      </div>
	</div>
      <div class="lxText">
        <div>
          <span>
            <i class="el-icon-map-location"></i>
            <h3>地址</h3>
          </span>
          <p>{{aboutDetail.address}}</p>
        </div>
        <div>
          <span>
            <i class="el-icon-phone-outline
"></i>
            <h3>电话</h3>
          </span>
          <p>{{aboutDetail.phone}}</p>
        </div>
        <div>
          <span>
            <i class="el-icon-message"></i>
            <h3>邮箱</h3>
          </span>
          <p>{{aboutDetail.email}}</p>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader';
export default {
  name: "mapDemo",
  data() {
	  return{
		//联系我们
		aboutDetail:Object  
	  };
  },
  mounted() {
    //联系我们
    this.usDetail();
	//DOM初始化完成进行地图初始化
	this.initMap();
  },
  methods: {
	  usDetail() {
	    this.$axios.get("/api/us/1").then((response) => {
	      this.aboutDetail= response.data.data;
		  console.log(response.data.data);
	    });
	  },
	  //地图初始化函数 initMap
	  initMap(){
		  AMapLoader.load({
		      key:"23775456e132427d68a9d5fa0db7ac02",             // 申请好的Web端开发者Key，首次调用 load 时必填
		      version:"1.4.15",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
		      plugins:['AMap.Scale','AMap.ToolBar'],// 需要使用的的插件列表，如比例尺'AMap.Scale'等
		  }).then((AMap)=>{
			  setTimeout(() => {
				  //渲染地图
				  var map = new AMap.Map("container",{  //设置地图容器id
					  zoom:15,//设置地图显示的缩放级别
					  expandZoomRange:true,
					  resizeEnable: true,
					  zooms:[5,30],
					  center: [112.471028, 34.628236], //中心点坐标
					  zoomEnable: true,
					  resizeEnable: true,
					  //scrollWheel: false, //鼠标滚动放大缩小地图  默认true
					  //mapStyle: "amap://styles/fresh"
				  });
				  // 创建一个 Marker 实例：
				  var marker = new AMap.Marker({
					  position: new AMap.LngLat(112.471028,34.628236),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
					  offset: new AMap.Pixel(0, 0)
				  });
				  //工具条控件、比例尺控件、鹰眼控件
				  map.addControl(new AMap.ToolBar());
				  map.addControl(new AMap.Scale());
				  
				  // 设置鼠标划过点标记显示的文字提示
				  marker.setTitle('洛阳弘义智慧城市建设服务有限公司');
				  // label默认蓝框白底左上角显示，样式className为：amap-marker-label
				  marker.setLabel({
					  direction:'center',
					  offset: new AMap.Pixel(0, -35),  //设置文本标注偏移量
					  content: "<div class='info' style='color:#1874CD; font-weight:bolder'>洛阳弘义智慧城市建设服务有限公司</div>", //设置文本标注内容
				  });
				  map.add(marker); //添加到地图
				
			  }, 100);
		  }).catch(e=>{
	        console.log(e);
	    });
	  },
  },
};
</script>