import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import $ from 'jquery'
import swiper from 'swiper'
import animated from 'animate.css'
import vueSwiper from 'vue-awesome-swiper'  //引入vue-awesome-swiper
import "swiper/css/swiper.css";
import axios from 'axios';//导入axios（不加拦截器）



import "./css/base.css"
import './css/icon/iconfont.css'
import "./css/response.css"



Vue.use(ElementUI);
Vue.use(animated)
Vue.use(vueSwiper)

Vue.prototype.$axios = axios    //全局注册，使用方法为:this.$axios
Vue.config.productionTip = false
Vue.prototype.$http = axios;//不加拦截器

Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
