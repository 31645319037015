<template>
  <div>
    <div class="singleTitle">
      <h3>走进弘义智慧</h3>
    </div>
    <div class="newCont">
      <!--以下是单篇信息-->
	  <p v-html="newsDetail.content"></p>
      <!-- <p>中国金融投资管理有限公司 (「中金投」或「公司」，连同其附属公司，统称「集团」) 是具有26年香港主板上市背景的多元化金融服务集团，股票代码HK00605。目前，集团在北京、香港、深圳、成都、重庆等城市设有子公司，形成了以北京为中心，向粤港澳大湾区、成渝西部中心城市发展的布局框架；旗下拥有小额贷款公司、典当行、融资性担保公司、商业保理公司、资产管理公司、基金管理公司等综合金融服务企业。</p> -->
      <!--以上是单篇信息-->
    </div>
  </div>
</template>
<script>
export default {
  data() {
	  return{
		  //新闻id
		  newsId: 0,
		//走进弘义
		newsDetail:Object  
	  };
  },
  mounted() {
	  //新闻id
	  this.newsId= this.$route.query.newsId;
	  if(this.newsId==undefined){
		  //走进弘义智慧
		  this.newsDetailByChannelId(3);
	  }else{
		  this.newsDetailById();
	  }
    
  },
  methods: {
	  newsDetailByChannelId(channelId) {
	    //根据当前栏目id查询单篇新闻
	    this.$axios
	      .get("/api/article/newsDetailByChannelId", {
	        params: {
	          channelId: channelId,
	        },
	      })
	      .then((response) => {
	        let data = response.data.data[0];
	        this.newsDetail = data;
	      });
	  },
	  newsDetailById() {
	    this.$axios.get('/api/article/newsDetailById', {
	      params: {
	        newsId: this.newsId
	      }
	    }).then(response => {
	      let data = response.data.data;
	      this.newsDetail = data;
		  });
		  },
  },
};
</script>
<style>
</style>