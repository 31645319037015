<template>
  <div class="newsBox">
    <ul class="imgNewsListUl">
      <li v-for="item in newsListArr">

        <router-link
          :to="{path:'/subpage/gsyw/news_xq',query:{newsId:item.id}}" 
          class="img_left"
        >
          <img :src="item.imgUrl" />
        </router-link>
        <div class="text_center">
          <router-link :to="{path:'/subpage/gsyw/news_xq',query:{newsId:item.id}}" >
            <h3>
				{{item.newsTitle}}
              <!-- 董贵波一行赴省旅游投资集团调研指导工作 -->
            </h3>
          </router-link>
		  <p>{{item.daodu}}...</p>

          <!-- <p>8月24日下午，浙江省国资委党委书记、主任董贵波来浙江省旅游投资集团调研指导工作，围绕贯彻落实省第十五次党代会精神和袁家军书记、王浩省长对国资国企工作重要指示精神，以及全省数字化改革推进会、经济稳进提质攻坚行动、助企纾困解难等部署要求，结合“大讨论大谋划大行动”开展深入调研，了解企业相关情况，听取意见建议，帮助企业解决实际问题。</p> -->
        </div>
        <div class="data_right">
          <h3>{{item.createTime.substring(5,10)}}</h3>
          <p>{{item.createTime.substring(0,4)}}</p>
        </div>

      </li>
    </ul>
    <div class="fenye">
		<el-pagination
		background
		  :total="total"
		  :page.sync="pageNum"
		  :limit.sync="pageSize"
		  :page-size="5"
		  @pagination="newsListByChannelId"
		  @current-change="handleCurrentChange"
		/>
      <!-- <el-pagination
        background
        layout="prev, pager, next"
        :total="1000"
      >
      </el-pagination> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
		//栏目id
		channelId:6,
      //新闻列表
      newsListArr: [],
      // 总条数
      total: 0,
      // 查询参数
      pageNum: 1,
      pageSize: 5,
    };
  },
  mounted() {
    //根据栏目Id查询新闻列表信息
    this.newsListByChannelId();
  },
  watch: {},
  methods: {
    handleCurrentChange(val){
      this.pageNum = val;
      this.newsListByChannelId();
    },
    newsListByChannelId(){
      let _this = this;
      //根据栏目Id查询新闻列表信息
      this.$axios.get('/api/article/newsByChannelList',{params:{channelId:this.channelId,pageNum:this.pageNum,pageSize:this.pageSize}}).then(response => {
        let data = response.data.rows;
        this.total = response.data.total;
        this.newsListArr=data;
      });

    },

  }
};
</script>