<template>
  <div class="footContainer">
    <div class="mainLayout">
      <div class="link">
        <div class="footLinkBox">
          <ul>
            <li>
              <router-link to="/">首页</router-link>
            </li>
            <li>
              <router-link to="/subpage/zjhyzh" >走进弘义智慧</router-link>
            </li>
            <li>
              <router-link to="/subpage/zjhwxjj" >走进华为新基建</router-link>
            </li>
            <li>
              <router-link to="/subpage/zhdj" >智慧党建</router-link>
              <div>
                <router-link
                  to="/"
                  v-for="item in channelListArr"
                >{{item.title}}</router-link>
              </div>
            </li>
            <li>
              <router-link to="/subpage/gsyw" >企业公告</router-link>
            </li>
            <li>
              <router-link to="/subpage/cpzx" >产品中心</router-link>
              <div>
                <router-link
                  :to="{path:'/subpage/cpzx/news_xq',query:{newsId:item.id}}" 
                  v-for="item in productListArr"
                >{{item.newsTitle.substring(0,4)}}</router-link>
              </div>
            </li>
            <li>
              <router-link to="/subpage/jjfa" >解决方案</router-link>
              <div>
                <router-link
                  :to="{path:'/subpage/jjfa/news_xq',query:{newsId:item.id}}" 
                  v-for="item in planListArr"
                >{{item.newsTitle.substring(0,4)}}</router-link>
              </div>
            </li>
            <li>
              <router-link to="/subpage/lxwm" >联系我们</router-link>
            </li>
          </ul>
        </div>
        <div>
          <img src="../assets/images/qrcode.jpg" />
        </div>
      </div>
      <div class="address" v-html="address">
        <!-- 电话：0379-63082690 邮箱：lyhyzh@163.com 地址：河南省洛阳市洛龙区政和路与广利街东南角停车场1楼<br />
        Copyright@2022 洛阳弘义智慧城市建设服务有限公司 版权所有 -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
		//智慧党建
		channelListArr:[],
		//产品中心
		productListArr:[],
		//解决方案
		planListArr:[],
		//底部信息
		address:Object,
		
	};
  },
  mounted() {
  	//根据专题Id查询专题及专栏列表
  	this.channelListByChannelId();
	//产品中心
	this.productListByChannelIdLimit();
	//解决方案
	this.planListByChannelIdLimit();
	//底部信息
	this.footerinfo();
  },
  methods:{
	  //智慧党建
	  channelListByChannelId() {
	    let _this = this;
	    //查询专题专栏列表
	    this.$axios
	      .get("/api/article/channelChildrenById", {
	        params: { channelId: 5,limit:4 },
	      })
	      .then((response) => {
	        let data = response.data.data;
			this.channelListArr=data;
	      });
	  },
	  //产品中心
	  productListByChannelIdLimit() {
	    let _this = this;
	    let channelId = 7;
	    let limit = 4;
	    this.$axios
	      .get("/api/article/newsByChannelIdTopLimit", {
	        params: {
	          channelId: channelId,
	          limit: limit,
	        },
	      })
	      .then((response) => {
	        let data = response.data.data;
	  			this.productListArr=data;
	      });
	  },
	  //解决方案
	  planListByChannelIdLimit() {
	    let _this = this;
	    let channelId = 8;
	    let limit = 4;
	    this.$axios
	      .get("/api/article/newsByChannelIdTopLimit", {
	        params: {
	          channelId: channelId,
	          limit: limit,
	        },
	      })
	      .then((response) => {
	        let data = response.data.data;
	  			this.planListArr=data;
	  			
	      });
	  },
	  //底部信息
	  footerinfo() {
	    this.$axios.get("/api/footer/1").then((response) => {
	      this.address = response.data.data.footer;
	    });
	  },
  }
};
</script>
<style>
.footContainer {
  background-color: #1c57a7;
  padding-top: 40px;
}
.link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #215fb3;
}
.link > div > img {
  width: 130px;
  height: 130px;
}
.footLinkBox ul {
  display: flex;
  gap: 50px;
}
.footLinkBox ul li > a {
  color: #fff;
  font-size: 16px;
}
.footLinkBox ul li > div {
  margin: 10px 0 30px 0;
}
.footLinkBox ul li > div > a {
  display: block;
  padding: 4px 0;
  color: #769dd2;
}
.address {
  border-top: 1px solid #18509c;
  text-align: center;
  color: #b8d1f2;
  padding: 30px 0;
  line-height: 1.8em;
}
</style>