<template>
  <div>
    <ul class="textListUl">
      <li v-for="item in newsListArr">
        <router-link :to="{path:'/subpage/zhdj/news_xq',query:{newsId:item.id}}" >{{item.newsTitle}}</router-link><span>{{item.createTime.substring(0,10)}}</span>
      </li>
    </ul>
    <div class="fenye">
    		<el-pagination
    		background
    		  :total="total"
    		  :page.sync="pageNum"
    		  :limit.sync="pageSize"
    		  :page-size="5"
    		  @pagination="newsListByChannelId"
    		  @current-change="handleCurrentChange"
    		/>
      <!-- <el-pagination
        background
        layout="prev, pager, next"
        :total="1000"
      >
      </el-pagination> -->
    </div>
  </div>
  
</template>
<script>
export default {
props: {
    childrenId: String,
	childrenName:String,
  },
  data() {
    return {
		//专题id
		channelId:5,
      //新闻列表
      newsListArr: [],
      // 总条数
      total: 0,
      // 查询参数
      pageNum: 1,
      pageSize: 5,
    };
  },
  created() {
	this.childrenId = this.$route.query.childrenId;
	this.childrenName= this.$route.query.childrenName;
  },
  mounted() {
	  //根据栏目Id查询新闻列表信息
	  this.newsListByChannelId();
	  
  },
  watch: {
  },
  methods: {
	  handleCurrentChange(val){
	    this.pageNum = val;
	    this.newsListByChannelId();
	  },
	  newsListByChannelId(){
	    let _this = this;
	    //根据栏目Id查询新闻列表信息
		if(this.childrenId==undefined){
			this.$axios.get('/api/article/channelAndNewsByChannelIdTop',{params:{channelId:this.channelId,pageNum:this.pageNum,pageSize:this.pageSize}}).then(response => {
			  let data = response.data.rows;
			  this.total = response.data.total;
			  this.newsListArr=data;
			});
		}else{
			//根据栏目Id查询新闻列表信息
			this.$axios.get('/api/article/newsByChannelList',{params:{channelId:this.childrenId,pageNum:this.pageNum,pageSize:this.pageSize}}).then(response => {
			  let data = response.data.rows;
			  this.total = response.data.total;
			  this.newsListArr=data;
			});
		}
	  },
  }
};
</script>